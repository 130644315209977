// NOTE: Please maintain by alphabetical order; it will be easier to search and look through the file.

export default {
  carousel: {
    buttons: {
      shopNow: "खरिद गर्नुस",
    },
  },
  cart: {
    cartIsEmpty: "कार्ट खाली छ",
    emptyCartButton: "खाली कार्ट",
    yourCart: "तपाईंको कार्ट",
  },
  cartDropdown: {
    checkOut: "चेकआउट",
    expandCart: "कार्ट विस्तार गर्नुहोस्",
    subTotal: "उप कूल",
  },
  categoryCarousel: {
    titles: {
      bestSeller: "अत्यधिक बिक्री भएको",
      relatedProducts: "सम्बन्धित उत्पादनहरु",
      trending: "ट्रेंडिंग",
    },
  },
  checkout: {
    address: {
      address: "ठेगाना",
      addressName: "ठेगाना नाम",
      city: "शहर",
      close: "बन्द गर्नुहोस",
      district: "जिल्ला",
      email: "इमेल",
      emailHelperText:
        "हामी तपाईको ईमेल कसैलाई पनि कहिले पनि साझेदारी गर्दैनौं",
      firstName: "नाम",
      lastName: "थर",
      phone: "फोन नम्बर",
      region: "क्षेत्र",
      saveChanges: "परिवर्तनहरू सुरक्षित गर्नुहोस",
      stateProvince: "राज्य/प्रदेश",
      submit: "बुझाउनुहोस्",
      zipCode: "जिप कोड",
    },
    delivery: {
      title: "ढुवानी",
      button: {
        saveChanges: "परिवर्तनहरू सुरक्षित गर्नुहोस",
        cancel: "रद्द गर्नुहोस्",
      },
      chooseDeliveryOptions: "वितरण विकल्प छनौट गर्नुहोस्",
      noSpecialInstructionProvided: "कुनै विशेष निर्देशन प्रदान गरिएको छैन।",
      specialInstruction: "विशेष निर्देशन",
      selectedDeliveryOption: "चयनित डेलिभरी विकल्प",
    },
    payment: {
      title: "भुक्तानी",
      choosePaymentMethod: "भुक्तानी विधि छनौट गर्नुहोस्",
    },
  },
  common: {
    error: "असफल भयो",
    loading: "लोड हुँदैछ",
  },
  footer: {
    contactUs: {
      title: "हामीलाई सम्पर्क गर्नुहोस",
      email: "इमेल",
      virtualBazar: "भर्चुअल बजार प्रा. लि.",
      callUsNow: "सम्पर्क गर्नुहोस",
      address: "अनमनगर, काठमाडौं",
    },
    followUs: {
      title: "हामीलाई फ्लो गर्नुहोस",
      downlaodOurApp: "हाम्रो याप डाउनलोड गर्नुहोस्",
    },
    mainText: `%{storeName} आयातकर्ताहरूबाट सीधा किन्नुहोस्!`,

    user: {
      myAccount: "मेरो खाता",
      myOrders: "मेरो अर्डर",
    },
  },
  featureStrip: {
    easyReturn: "सजिलै फिर्ती",
    freeDelivery: "नि: शुल्क वितरण",
    genuineProducts: "वास्तविक उत्पादनहरू",
  },
  featuredCategory: {
    buttons: {
      viewMore: "अझै हेर्नुहोस",
      viewProducts: "उत्पादनहरू हेर्नुहोस्",
    },
  },
  form: {
    fields: {
      confirmPassword: "पासवर्ड सुनिश्चित गर्नुहोस",
      dontShowName: `मेरो नाम नदेखाउनुहोस्`,
      email: "इमेल",
      emailHelperText:
        "हामी तपाईको ईमेल कसैलाई पनि कहिले पनि साझेदारी गर्दैनौं",
      fullName: "पूरा नाम",
      password: "पासवर्ड",
      title: "शीर्षक",
      yourReview: "तपाईको समीक्षा",
      yourQuestion: "तपाईंको प्रश्न",
    },
    errors: {
      required: "आवाश्यक",
      invalidEmail: "अवैध ईमेल ठेगाना",
      phoneNumber: "अवैध फोन नम्बर, १० अंकको हुनुपर्दछ",
      passwordConfirmation:
        "पासवर्ड कम्तिमा एउटा माथिल्लो केस, एक सानो केस, एक अ ,्क, एक विशेष वर्ण र न्यूनतम six लम्बाइमा हुनुपर्दछ।",
      passwordDontMatch:
        "पासवर्ड मेल खाँदैन कृपया तपाईंको पासवर्ड पुन: टाइप गर्नुहोस्।",
      zipcode: "अवैध जिपकोड, कुल पाँच सकारात्मक अंकहरू हुनुपर्दछ",
    },
    placeholder: {
      email: "कृपया तपाईंको ईमेल प्रविष्ट गर्नुहोस्",
      password: "कृपया तपाईको पासवर्ड प्रविष्ट गर्नुहोस्",
      retypePassword: "कृपया तपाईको पासवर्ड फेरी टाइप गर्नुहोस्",
    },
  },
  guest: {
    title: "अतिथिको रूपमा जारी राख्नुहोस्",
  },
  header: {
    searchboxPlaceHolder: "म खोज्दै छु",
  },
  login: {
    forgetpassword: "पासवर्ड बिर्सनुभयो?",
    login: "लगईन",
    orloginWith: "वा, लगइन गर्नुहोस्",
    here: "गर्नुहोस्",
    newMember: "नयाँ सदस्य? ",
    title: "यो स्टोरमा तपाईंलाई स्वागत छ! कृपया लगईन गर्नुहोस्।",
  },
  orderSummary: {
    deliveryOptions: "वितरण विकल्पहरू",
    orderSummary: {
      applyCode: "कोड लागू गर्नुहोस्",
      discount: "छुट",
      proceedToCheckout: "चेकआउट गर्न अगाडि बढ्नुहोस्",
      removeCode: "कोड हटाउनुहोस्",
      subTotal: "उप कूल",
      shipping: "शिपिंग",
      title: "अर्डर सारांश",
      totalItems: "कुल वस्तुहरू",
      tax: "कर",
      total: "कुल",
    },
    procceedToPayment: "भुक्तानी गर्न अगाडि बढ्नुहोस्",
    shippingAndBilling: {
      billToSameAddress: "उही ठेगानामा बिल",
      title: "शिपिंग र बिल्लिंग",
    },
  },
  orderDetails: {
    billingAddress: "बिल ठेगाना",
    shippingAddress: "शिपिंग ठेगाना",
    order: "अर्डर",
    orderSuccess: "अर्डर सफलतापूर्वक प्लेस",
    orderSentToEmail: "तपाईंको अर्डर जानकारी तपाईंको ईमेलमा पठाइनेछ ।",
    payment: "भुक्तानी",
    product: {
      price: "मूल्य",
      quantity: "मात्रा",
      total: "कुल",
    },
    shipping: "शिपिंग",
    specialInstruction: "विशेष निर्देशन",
  },
  productDetail: {
    availability: "उपलब्धता",
    buttons: {
      addToCart: "कार्टमा थप्नुहोस्",
    },
    description: {
      details: "विवरण",
      specifications: "निर्दिष्टीकरण",
      title: "वर्णन",
    },
    reviews: {
      buttontext: "समीक्षा लेख्नुहोस्",
      title: "समीक्षा",
      title_plural: "समीक्षाहरु",
      reviewedIn: "%{date} मा समीक्षा गरियो",
    },
    soldBy: "%{name}द्वारा बेचिएको",
  },
  productBanner: {
    buttons: {
      readMore: "थप पढ्नुहोस्",
    },
    category: "Gaming Chair",
    title: "सस्तो दरमा",
  },
  promoCode: {
    promoCodeApplied: "प्रोमोकोड लागू गरियो",
    promoCodeRemoved: "प्रोमोकोड हटाईयो",
    title: "प्रोमो कोड",
  },
  questions: {
    askQuestionButton: "तपाईंको प्रश्नहरू सोध्नुहोस्",
    button: "प्रश्न थप्नुहोस्",
    customerQuestionsAndAnswers: "ग्राहक प्रश्न र उत्तरहरू",
    cancel: "रद्द गर्नुहोस्",
    questionSubmitted: "प्रश्न बुझाइयो",
    title: "के तपाइँसँग कुनै प्रश्न छ?",
    questionedIn: "%{date}मा %{displayName} द्वारा",
  },
  review: {
    button: "समीक्षा जोड्नुहोस्",
    cancelButton: "रद्द गर्नुहोस्",
    loginToAddReview: "समीक्षा थप्न कृपया लगईन गर्नुहोस्",
    reviewSubmitted: "समीक्षा सबमिट गरिएको",
    title: "तपाईंको समीक्षा थप्नुहोस्",
  },
  socials: {
    facebook: "फेसबुक",
    google: "गूगल",
  },
  signup: {
    alreadymember: "पहिले नै सदस्य हुनुहुन्छ?",
    orSignUpwith: "वा, साइन अप गर्नुहोस्",
    privacypolicy:
      "साइन अप क्लिक गरेर, म यो स्टोरको प्रयोग सर्तहरू र गोपनीयता नीतिमा सहमत छु।",
    signUp: "साइन अप",
    title: "तपाईंको खाता सिर्जना गर्नुहोस्",
  },
  user: {
    addressBook: {
      address: "ठेगाना",
      addressName: "ठेगाना नाम",
      addNewAddress: "नयाँ ठेगाना थप्नुहोस्",
      button: {
        addNewAddress: "नयाँ ठेगाना थप्नुहोस्",
        cancel: "रद्द गर्नुहोस्",
        change: "परिवर्तन",
        edit: "सम्पादन गर्नुहोस्",
        save: "बचत गर्नुहोस्",
      },
      defaultShippingAddress: "पूर्वनिर्धारित शिपिंग ठेगाना",
      defaultBillingAddress: "पूर्वनिर्धारित बिल ठेगाना",
      editMyAddress: "मेरो ठेगाना सम्पादन गर्नुहोस्",
      fullName: "पुरा नाम",
      noAddress: "कुनै ठेगाना अझै जोडिएको छैन!",
      phoneNumber: "फोन नम्बर",
      title: "ठेगाना पुस्तिका",
    },
    dashboard: {
      title: "ड्यासबोर्ड",
    },
    dropdown: {
      logout: "बाहिर निस्कनुहोस्",
      login: "लग - इन",
      myAccount: "मेरो खाता",
      signUp: "साइन अप",
    },
    myOrders: {
      emptyOrderList: "कुनै अर्डर छैन !",
      order: "अर्डर",
      placedOn: "अर्डर राखिएको",
      status: "स्थिति",
      quantity: "मात्रा",
      title: "मेरो अर्डरहरू",
    },
    myReviews: {
      dropdown: {
        all: "सबै",
        deleteReview: "समीक्षा मेटाउनुहोस्",
        editReview: "समीक्षा सम्पादन गर्नुहोस्",
        history: "ईतिहास",
        toBeReviewed: "समीक्षा गर्न बाँकी",
      },
      noReviews: "अझै पूनरावलोकनहरू छैनन् !",
      reviewedOn: "%{date} मा समीक्षा गरियो",
      pendingReview: "समीक्षाको लागि बाँकी",
      status: "स्थिति",
      title: "मेरो समीक्षाहरू",
    },
    myQuestions: {
      answer: "उत्तर",
      answered: "जवाफ दिए",
      dropdown: {
        all: "सबै",
        deleteReview: "प्रश्न मेटाउनुहोस्",
        editReview: "प्रश्न सम्पादन गर्नुहोस्",
        history: "ईतिहास",
        toBeReviewed: "समीक्षा गर्न बाँकी",
      },
      noQuestions: "अझै कुनै प्रश्नहरू छैनन् !",
      toBeReviewed: "समीक्षा गर्न बाँकी",
      question: "प्रश्न",
      questionedOn: "%{date} मा प्रश्न गरीयो",
      status: "स्थिति",
      title: "मेरो प्रश्नहरु",
    },
    myWishList: {
      available: "उपलब्ध छ",
      alert: {
        button: {
          ok: "ठिक छ",
          cancel: "रद्द गर्नुहोस्",
        },
        title: "के तपाईँ निश्चित हुनुहुन्छ?",
        text: "एक पटक मेटाईएपछि, तपाई पुन: प्राप्ति गर्न सक्नुहुन्न!",
      },
      availability: "उपलब्धता",
      dropDown: {
        addNewList: "नयाँ सूची थप्नुहोस्",
        default: "पूर्वनिर्धारित",
        editThisList: "यो सूची सम्पादन गर्नुहोस्",
        manageWishlist: "ईच्छा सूची प्रबन्ध गर्नुहोस्",
        makePrivate: "निजी बनाउनुहोस्",
        moveItem: "वस्तु सार्नुहोस्",
        removeItem: "वस्तु हटाउनुहोस्",
        shareWishlist: "ईच्छा सूची साझा गर्नुहोस्",
      },
      form: {
        deleteWishlist: "यो ईच्छा सूची हटाउनुहोस्",
        isPrivate: "निजी छ",
        isDefault: "पूर्वनिर्धारित छ",
        name: "नाम",
        save: "बचत गर्नुहोस्",
      },
      noProduct: "यस ईच्छा सूचीमा कुनै उत्पादनहरू छैनन्",
      product: {
        price: "मूल्य",
        quantity: "मात्रा",
      },
      placedOn: "ईच्छा सूची राखिएको मिति",
      title: "मेरो ईच्छा सूचीहरू",
      outOfStock: "स्टक बाहिर",
      wishlist: "ईच्छा सूची",
    },
    profile: {
      billingAddress: "बिल ठेगाना",
      contactInfo: "सम्पर्क जानकारी",
      shippingAddress: "ढुवानी ठेगाना",
      title: "प्रोफाइल",
    },
  },
};
