import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { I18n } from "react-redux-i18n";

import client from "services/spreeClient";
import { addToCartRequest } from "appRedux/cart/actions";
import { addToWishlistRequest } from "appRedux/wishlist/actions";
import { fetchProductDetail } from "appRedux/productDetail/actions";
import { fetchAllProductQuestions } from "appRedux/productQuestion/actions";
import { fetchAllProductReview } from "appRedux/productReview/actions";
import { fetchWishlistRequest } from "appRedux/wishlist/actions";

import ProductDisplay from "components/Product/Display";
import ProductCustomerQA from "components/Product/CustomerQA/ProductCustomerQA";
import Layout from "components/Layout/Layout";
import ProductDetailSkeleton from "components/Skeleton/ProductDetailSkeleton";
import CategoryCarousel from "components/Carousel/CategoryCarousel";

import { productDefaultMetaImage } from "config/seo";
import { isEnvironmentVariableEnabled } from "utils/isEnvironmentVariableEnabled";

const ProductShow = ({
  isTaxonProductsLoading,
  taxonRelatedProducts,
  categoryId,
}) => {
  const { slug } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const bearerToken = localStorage.getItem("bearerToken");

  const { product, isLoading } = useSelector(
    ({ productDetail }) => productDetail
  );

  const {
    productQuestions: questions,
    isLoading: isProductQuestionsLoading,
  } = useSelector(({ productQuestion }) => productQuestion);
  const {
    wishlist: {
      wishlistData: { wishlists, isLoading: isWishlistLoading },
    },
  } = useSelector((state) => state.wishlist);

  useEffect(() => {
    if (bearerToken && product.id) {
      dispatch(fetchWishlistRequest(product.default_variant.id));
    }
  }, [product]);

  useEffect(() => {
    if (
      isEnvironmentVariableEnabled(
        process.env.REACT_APP_PRODUCT_QUESTIONS_ENABLED
      )
    ) {
      if (product.id) {
        dispatch(fetchAllProductQuestions(product.id));
      }
    }
  }, [product.id]);

  useEffect(() => {
    if (
      isEnvironmentVariableEnabled(
        process.env.REACT_APP_PRODUCT_REVIEWS_ENABLED
      )
    ) {
      if (product.id) {
        dispatch(fetchAllProductReview(product.id));
      }
    }
  }, [product.id]);

  useEffect(() => {
    dispatch(fetchProductDetail(slug));
  }, [slug]);

  useEffect(() => {
    if (!bearerToken) {
      if (!localStorage.getItem("orderToken")) {
        createCart();
      }
    } else {
      createUserCart();
    }
  }, []);

  function addItemToCart({ variantId, quantity }) {
    dispatch(addToCartRequest({ variantId, quantity }));
  }

  function addItemToWislist({ variantId, quantity, wishlistId }) {
    dispatch(
      addToWishlistRequest({
        variantId,
        quantity,
        wishlistId,
        remark: "",
      })
    );
    dispatch(fetchWishlistRequest(variantId));
  }

  async function createUserCart() {
    await client.cart.create({ bearerToken });
  }

  async function createCart() {
    const response = await client.cart.create();

    localStorage.setItem(
      "orderToken",
      response.success().data.attributes.token
    );
  }

  if (
    isLoading ||
    isWishlistLoading ||
    !product.id ||
    isTaxonProductsLoading ||
    isProductQuestionsLoading
  ) {
    return <ProductDetailSkeleton />;
  }

  const images = product.images[0]?.styles ?? [];
  const image = images[images.length - 1];
  const metaImage = image
    ? { ...image, src: image.url }
    : productDefaultMetaImage;

  return (
    <Layout
      title={product.name}
      description={product.description}
      path={pathname}
      metaImage={metaImage}
    >
      <ProductDisplay
        wishlists={wishlists}
        product={product}
        slug={slug}
        addToCart={addItemToCart}
        addToWishlist={addItemToWislist}
      />
      <ProductCustomerQA
        slug={slug}
        questions={questions}
        isLoading={isProductQuestionsLoading}
      />
      <CategoryCarousel
        products={taxonRelatedProducts}
        categoryId={categoryId}
        taxonRelatedProducts={taxonRelatedProducts}
        title={I18n.t("categoryCarousel.titles.relatedProducts")}
        className="bg-light border-bottom"
      />
    </Layout>
  );
};

export default ProductShow;
