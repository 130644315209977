import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translate } from "react-redux-i18n";
import {
  faEllipsisV,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

// import extractImage from "utils/extractImage";

import QuestionList from "./QuestionList";
import extractImage from "utils/extractImage";

const QuestionItem = ({
  question,
  handleEditQuestion,
  handleDeleteQuestion,
}) => {
  const { images } = question.product;
  const image = extractImage(images);

  const questionedDate = new Date(question.created_at).toDateString().slice(4);

  return (
    <div className="card dashboard-card shadow-sm border-0 mb-2 p-3">
      <div className="order-basic-info text-muted d-flex justify-content-between align-items-center">
        <p className="mb-0">
          <Translate
            value="user.myQuestions.questionedOn"
            date={questionedDate}
          />
        </p>

        <div>
          <button
            className="btn p-1"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </button>
          <ul className="dropdown-menu border-0 text-dark shadow">
            {!question.product_answer && (
              <li>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => handleEditQuestion(question.id)}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />{" "}
                  <Translate value="user.myQuestions.dropdown.editQuestion" />
                </button>
              </li>
            )}
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() =>
                  handleDeleteQuestion({
                    questionId: question.id,
                    productId: question.product.id,
                  })
                }
              >
                <FontAwesomeIcon icon={faTrashAlt} />{" "}
                <Translate value="user.myQuestions.dropdown.deleteQuestion" />
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <QuestionList
          image={image}
          title={question.product.name}
          questionStatus={question.product_answer}
        />
      </div>
      <div>
        <p className="mb-2">
          {" "}
          <strong>
            <Translate value="user.myQuestions.question" /> :{" "}
          </strong>
          <span className="text-muted">{question.content}</span>
        </p>
        {question.product_answer && (
          <p>
            <strong>
              <Translate value="user.myQuestions.answer" /> :{" "}
            </strong>
            <span className="text-muted">
              {question.product_answer.content}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default QuestionItem;
