import React, { useState } from "react";
import YouTube from "@u-wave/react-youtube";

import { Carousel } from "react-responsive-carousel";

import ProductImage from "./ProductImage";

const ProductVideo = ({ videoUrl, currentIndex, videoSlideIndex }) => {
  const videoId = videoUrl.replace("https://www.youtube.com/embed/", "");

  return (
    <YouTube
      height="100%"
      width="100%"
      video={videoId}
      paused={currentIndex !== videoSlideIndex}
    />
  );
};

// in case videos extension is disabled, fallback to empty array and avoid crash
const ProductImageSlider = ({ images, videos = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const customRenderThumb = (sliderItems) =>
    sliderItems.map(({ props: slideProps }) => {
      const { thumbnailUrl, src } = slideProps;

      if (thumbnailUrl) {
        return <img src={thumbnailUrl} />;
      }

      return <img src={src} />;
    });

  const imagesList = images.map(({ styles, id }) => {
    const imgSrc = styles[styles.length - 1].url;

    return <img src={`${imgSrc}`} />;
  });

  const videosList = videos.map(
    (
      { id, youtube_embed_link: videoUrl, youtube_thumbnail: thumbnailData },
      index
    ) => {
      const { table } = thumbnailData;
      const thumbnailUrl = table?.url;

      return (
        <ProductVideo
          videoSlideIndex={index + imagesList.length}
          currentIndex={currentIndex}
          videoUrl={videoUrl}
          thumbnailUrl={thumbnailUrl}
        />
      );
    }
  );

  const slideContent = [...imagesList, ...videosList];

  return (
    <div className="col-md-5">
      <div className="mb-4 thumbnail-slider sticky-top">
        <Carousel
          onChange={(e) => setCurrentIndex(e)}
          useKeyboardArrows
          //showArrows={true}
          thumbWidth="5em"
          renderThumbs={customRenderThumb}
          swipeable
        >
          {slideContent}
        </Carousel>
      </div>
    </div>
  );
};

export default React.memo(ProductImageSlider);
