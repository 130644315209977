import React, { useState } from 'react'
import ReactStars from 'react-rating-stars-component'
import { Link } from 'react-router-dom'
import { I18n, Translate } from 'react-redux-i18n'
import { withRouter, useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import { Field } from 'redux-form-normalize-on-blur'
import { useDispatch, useSelector, connect } from 'react-redux'

import {
  renderField,
  renderTextAreaField,
} from 'components/Form/FormFieldRender'

import {
  createProductReviewRequest,
  editProductReviewRequest,
} from 'appRedux/productReview/actions'

import trimmer from 'utils/trimmer'
import { required } from 'utils/fieldValidation'
import REACT_STARS from 'constants/reactStarsConfig'
import ROUTES from 'constants/routes'

function ReviewForm({ handleSubmit, setToggleReviewForm, history }) {
  const { reviewId } = useParams()

  const {
    userReview: { isLoading, error },
    reviewSubmitted,
  } = useSelector((state) => state.productReview)
  const { reviews } = useSelector((state) => state.user.reviewsData)
  const {
    product: { id },
  } = useSelector(({ productDetail }) => productDetail)

  const review = reviews.find((review) => {
    return review.id === reviewId
  })

  const [starsValue, setStarsValue] = useState(
    reviewId ? review.rating : REACT_STARS.INITIAL_VALUE,
  )

  const dispatch = useDispatch()

  const submit = (values) => {
    if (reviewId) {
      return dispatch(
        editProductReviewRequest({
          productId: id,
          title: values.title,
          review: values.review,
          name: values.name,
          rating: starsValue.toString(),
          showIdentifier: values.dontShowIdentifier,
          reviewId,
          history,
        }),
      )
    }
    dispatch(
      createProductReviewRequest({
        productId: id,
        title: values.title,
        review: values.review,
        name: values.name,
        rating: starsValue.toString(),
        showIdentifier: values.dontShowIdentifier,
      }),
    )
  }

  if (!reviewId && reviewSubmitted) {
    setToggleReviewForm(false)
  }

  const bearerToken = localStorage.getItem('bearerToken')

  if (!bearerToken) {
    return (
      <>
        <Link to={ROUTES.LOGIN.INDEX} className="btn btn-primary py-3">
          <Translate value="review.loginToAddReview" />
        </Link>
        <button
          type="button"
          onClick={() => setToggleReviewForm(false)}
          className="btn btn-secondary"
        >
          <Translate value="review.cancelButton" />
        </button>
      </>
    )
  }
  const handleCancel = () => {
    if (reviewId) {
      return history.push(ROUTES.USER.REVIEW.INDEX)
    }
    setToggleReviewForm(false)
  }

  // TODO: @offmausam :add skeleton ui
  if (isLoading) {
    return null
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="mb-3 mt-3">
        <label htmlFor="exampleInputEmail1" className="form-label">
          <Translate value="review.title" />
        </label>
        <ReactStars
          value={starsValue}
          onChange={(newRating) => setStarsValue(newRating)}
          count={REACT_STARS.COUNT}
          size={REACT_STARS.SIZE}
          activeColor={REACT_STARS.ACTIVE_COLOR}
        />
      </div>

      <Field
        name="name"
        type="text"
        component={renderField}
        normalizeOnBlur={trimmer}
        label={`${I18n.t('form.fields.fullName')} *`}
        validate={[required]}
      />
      <Field
        name="title"
        type="text"
        component={renderField}
        normalizeOnBlur={trimmer}
        label={`${I18n.t('form.fields.title')} *`}
        validate={[required]}
      />
      <Field
        name="review"
        component={renderTextAreaField}
        normalizeOnBlur={trimmer}
        label={`${I18n.t('form.fields.yourReview')} *`}
        validate={[required]}
      />

      <div className="mb-3 form-check">
        <Field
          name="dontShowIdentifier"
          type="checkbox"
          component="input"
          className="form-check-input"
          id="exampleCheck1"
        />

        <label className="form-check-label" htmlFor="exampleCheck1">
          <Translate value="form.fields.dontShowName" />
        </label>
      </div>

      <button type="submit" className="btn btn-primary py-2 px-3">
        <Translate value="review.button" />
      </button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn btn-secondary"
      >
        <Translate value="review.cancelButton" />
      </button>
    </form>
  )
}

const mapStateToProps = (state, props) => {
  const { reviews } = state.user.reviewsData

  const review = reviews.find((review) => {
    return review.id === props.match.params.reviewId
  })

  if (review) {
    return {
      initialValues: {
        name: review.name,
        title: review.title,
        review: review.review,
        dontShowIdentifier: review?.show_identifier,
      },
    }
  }
}

export default withRouter(
  connect(mapStateToProps)(reduxForm({ form: 'review' })(ReviewForm)),
)
