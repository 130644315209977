import swal from 'sweetalert'
import { I18n } from 'react-redux-i18n'

import PRODUCT_REVIEW_ACTIONS from './constants'

export const fetchAllProductReview = (id) => ({
  type: PRODUCT_REVIEW_ACTIONS.FETCH_ALL_PRODUCT_REVIEW_REQUEST,
  id,
})

export const fetchAllProductReviewFail = () => ({
  type: PRODUCT_REVIEW_ACTIONS.FETCH_ALL_PRODUCT_REVIEW_FAILED,
})

export const fetchAllProductReviewSuccess = (reviews) => ({
  type: PRODUCT_REVIEW_ACTIONS.FETCH_ALL_PRODUCT_REVIEW_SUCCESS,
  reviews,
})

export const createProductReviewRequest = ({
  review,
  title,
  name,
  rating,
  productId,
  showIdentifier,
}) => ({
  type: PRODUCT_REVIEW_ACTIONS.CREATE_PRODUCT_REVIEW_REQUEST,
  review,
  name,
  rating,
  showIdentifier,
  title,
  productId,
})

export const createProductReviewFail = () => ({
  type: PRODUCT_REVIEW_ACTIONS.CREATE_PRODUCT_REVIEW_FAILED,
})

export const createProductReviewSuccess = () => {
  swal(
    I18n.t('review.reviewSubmitted'),
    'Your review has been submitted',
    'success',
  )

  return {
    type: PRODUCT_REVIEW_ACTIONS.CREATE_PRODUCT_REVIEW_SUCCESS,
  }
}

export const editProductReviewRequest = ({
  review,
  title,
  name,
  rating,
  productId,
  showIdentifier,
  reviewId,
  history,
}) => ({
  type: PRODUCT_REVIEW_ACTIONS.EDIT_PRODUCT_REVIEW_REQUEST,
  review,
  name,
  rating,
  showIdentifier,
  title,
  productId,
  reviewId,
  history,
})

export const editProductReviewFail = () => ({
  type: PRODUCT_REVIEW_ACTIONS.EDIT_PRODUCT_REVIEW_FAILED,
})

export const editProductReviewSuccess = () => {
  swal('Review Updated', 'Your review has been updated', 'info')

  return {
    type: PRODUCT_REVIEW_ACTIONS.EDIT_PRODUCT_REVIEW_SUCCESS,
  }
}

export const removeProductReviewRequest = ({
  productId,
  reviewId,
  currentPage,
  userId,
}) => ({
  type: PRODUCT_REVIEW_ACTIONS.REMOVE_PRODUCT_REVIEW_REQUEST,
  productId,
  reviewId,
  currentPage,
  userId,
})

export const removeProductReviewFail = () => ({
  type: PRODUCT_REVIEW_ACTIONS.REMOVE_PRODUCT_REVIEW_FAILED,
})

export const removeProductReviewSuccess = () => {
  swal('Review Removed', 'Your review has been removed', 'info')

  return {
    type: PRODUCT_REVIEW_ACTIONS.REMOVE_PRODUCT_REVIEW_SUCCESS,
  }
}
