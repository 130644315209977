function getFullName(information) {
  if (!information) return "";

  let fullName = "";

  if (information.firstname) {
    fullName += information.firstname;
  }

  if (information.lastname) {
    if (fullName) {
      fullName += " ";
    }
    fullName += information.lastname;
  }

  return fullName;
}

export { getFullName };
