import React, { useEffect, useState } from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { Link, withRouter } from "react-router-dom";
import { Field } from "redux-form-normalize-on-blur";
import { I18n, Translate } from "react-redux-i18n";
import { useDispatch, useSelector, connect } from "react-redux";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import Footer from "components/Footer/Footer";
import {
  renderField,
  renderPasswordField,
} from "components/Form/FormFieldRender";
import Layout from "components/Layout/Layout";

import { registration, loginWithFacebookRequest } from "appRedux/auth/actions";
import { addEmailforCheckOut } from "appRedux/checkOut/actions";

import ROUTES from "constants/routes";
import AUTH_ACTIONS from "appRedux/auth/constants";

import trimmer from "utils/trimmer";
import {
  required,
  email,
  password,
  passwordsMustMatch,
} from "utils/fieldValidation";

const Signup = ({ history, handleSubmit, submitting, emailValue }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const errors = useSelector((state) => state.auth.registration.errors);
  const errorOnFacebookRegistration = useSelector(
    (state) => state.auth.login.errors
  );
  const { item_count } = useSelector((state) => state.cart.cartData);
  const isAuthenticated = useSelector(
    (state) => state.auth.login.isAuthenticated
  );
  const isAuthenticating = useSelector(
    (state) => state.auth.registration.isAuthenticating
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({ type: AUTH_ACTIONS.LOGIN_FAILED, error: null });
    };
  }, []);

  if (isAuthenticated) {
    dispatch(addEmailforCheckOut(emailValue));
    if (item_count !== 0) {
      history.push(ROUTES.CHECKOUT.ADDRESS);
    } else {
      history.push(ROUTES.USER.PROFILE);
    }
  }

  const submit = (values) => {
    dispatch(
      registration(values.email, values.password, values.confirmPassword)
    );
  };

  const responseFacebook = (response) => {
    if (response.status === "unknown") {
      return null;
    }
    const {
      graphDomain,
      accessToken,
      data_access_expiration_time,
      userId,
      email = null,
      name,
      picture,
    } = response;

    dispatch(
      loginWithFacebookRequest({
        provider: graphDomain,
        uid: userId,
        info: {
          email,
          name,
          image: picture.data.url,
        },
        credentials: {
          token: accessToken,
          expires_at: data_access_expiration_time,
          expires: true,
        },
        extra: {
          raw_info: {
            email,
            name,
            id: userId,
          },
        },
      })
    );
  };

  return (
    <Layout
      title="Sign up"
      description="Sign up to Trendline store"
      path={ROUTES.SIGNUP.INDEX}
    >
      <div className="container mb-5 mt-4">
        <form onSubmit={handleSubmit(submit)}>
          <div className="mb-md-4 row align-items-center">
            <h2 className="col-12 col-md-6">
              <Translate value="signup.title" />
            </h2>
          </div>
          <div className="position-relative row p-sm-3 p-lg-5 login-card ">
            {isAuthenticating && (
              <div className="backdrop">
                <div className="spinner">Loading...</div>
              </div>
            )}

            {errors &&
              errors.email &&
              errors.email.map((err, i) => {
                return <p key={i}>Email : {err}</p>;
              })}
            {errors &&
              errors.password &&
              errors.password.map((err, i) => {
                return <p key={i}>Password : {err}</p>;
              })}
            {errorOnFacebookRegistration && (
              <p>{errorOnFacebookRegistration}</p>
            )}

            <div className="col-12 col-lg-6 ">
              <div className="me-lg-4  mt-3 mt-sm-0">
                <Field
                  name="email"
                  type="email"
                  component={renderField}
                  label={`${I18n.t("form.fields.email")}*`}
                  placeholder={I18n.t("form.placeholder.email")}
                  normalizeOnBlur={trimmer}
                  helpText={I18n.t("form.fields.emailHelperText")}
                  validate={[required, email]}
                />

                <Field
                  name="password"
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  component={renderPasswordField}
                  label={`${I18n.t("form.fields.password")}*`}
                  placeholder={I18n.t("form.placeholder.password")}
                  normalizeOnBlur={trimmer}
                  validate={[required, password]}
                />
                <Field
                  name="confirmPassword"
                  showPassword={showConfirmPassword}
                  placeholder={I18n.t("form.placeholder.retypePassword")}
                  label={`${I18n.t("form.fields.confirmPassword")}*`}
                  setShowPassword={setShowConfirmPassword}
                  component={renderPasswordField}
                  normalizeOnBlur={trimmer}
                  validate={[required, passwordsMustMatch]}
                />
                <button
                  disabled={submitting}
                  type="submit"
                  className="btn btn-primary rounded w-100 w-md-auto  p-3 mb-1"
                >
                  <Translate value="signup.signUp" />
                </button>
                <p className="text-start text-muted ">
                  <small>
                    <Translate value="signup.privacypolicy" />
                  </small>
                </p>
              </div>
            </div>
            <div className="col-12 mb-3 col-lg-6 mt-sm-4 text-center align-self-center">
              <p className="text-start text-muted">
                <Translate value="signup.orSignUpwith" />
              </p>
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    type="button"
                    className="btn facebook-btn rounded w-100 w-md-auto p-3"
                  >
                    <span className="d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon className="me-1" icon={faFacebook} />
                      <span>
                        <Translate value="socials.facebook" />
                      </span>
                    </span>
                  </button>
                )}
              />

              <button
                type="button"
                className="btn google-btn rounded w-100 w-md-auto p-3 mt-3"
              >
                <span className="d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon className="me-1" icon={faGoogle} />
                  <span>
                    <Translate value="socials.google" />
                  </span>
                </span>
              </button>

              <p className="text-start mt-4">
                {" "}
                <Translate value="signup.alreadymember" />{" "}
                <Link to={ROUTES.LOGIN.INDEX}>
                  Login <Translate value="login.here" />
                </Link>
              </p>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </Layout>
  );
};

const selector = formValueSelector("registration");

export default withRouter(
  connect((state) => ({
    emailValue: selector(state, "email"),
  }))(reduxForm({ form: "registration" })(Signup))
);
