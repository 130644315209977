import React from "react";
import { Translate } from "react-redux-i18n";

const ReviewList = ({ image, title, qty, price, reviewStatus }) => {
  return (
    <div className="order-card-item d-flex flex-row justify-content-between align-items-center py-3">
      <div className="d-flex flex-row align-items-start justify-content-between">
        <img src={image} alt={title} className="product-image me-3" />
        <div className="product-detail">
          <p className="mb-0 font-weight-semibold">{title}</p>
          {/* <p className="mb-0 text-muted">
            Qty: <span>{qty}</span>
          </p> */}
          <p className="mb-0 text-muted">
            <Translate value="user.myReviews.status" />:{" "}
            <span>{reviewStatus}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReviewList;
