import USER_ACTIONS from './constants';

export const userInfoRequest = () => ({
  type: USER_ACTIONS.USER_INFO_REQUEST,
});

export const userInfoSuccess = ({ userInfo }) => ({
  type: USER_ACTIONS.USER_INFO_SUCCESS,
  userInfo,
});

export const orderListRequest = (currentPage) => ({
  type: USER_ACTIONS.ORDER_LIST_REQUEST,
  currentPage,
});

export const orderListSuccess = ({ orders, ordersPageMetaData }) => ({
  type: USER_ACTIONS.ORDER_LIST_SUCCESS,
  orders,
  ordersPageMetaData,
});

export const userInfoError = (error) => ({
  type: USER_ACTIONS.USER_INFO_FAILED,
  error,
});

export const fetchOrderCurrentPage = (ordersCurrentPage) => ({
  type: USER_ACTIONS.ORDER_CURRENT_PAGE,
  ordersCurrentPage,
});

export const resetUserData = () => ({
  type: USER_ACTIONS.RESET_USER_DATA,
});

export const fetchReviewsRequest = ({ currentPage, userId }) => ({
  type: USER_ACTIONS.FETCH_USER_REVIEWS_REQUEST,
  currentPage,
  userId,
});

export const fetchReviewsSuccess = ({ reviews, reviewsPageMetaData }) => ({
  type: USER_ACTIONS.FETCH_USER_REVIEWS_SUCCESS,
  reviews,
  reviewsPageMetaData,
});

export const fetchReviewsCurrentPage = (reviewsCurrentPage) => ({
  type: USER_ACTIONS.REVIEWS_CURRENT_PAGE,
  reviewsCurrentPage,
});

export const fetchQuestionsRequest = ({ currentPage, userId }) => ({
  type: USER_ACTIONS.FETCH_USER_QUESTIONS_REQUEST,
  currentPage,
  userId,
});

export const fetchQuestionsSuccess = ({
  questions,
  questionsPageMetaData,
}) => ({
  type: USER_ACTIONS.FETCH_USER_QUESTIONS_SUCCESS,
  questions,
  questionsPageMetaData,
});

export const fetchQuestionsCurrentPage = (questionsCurrentPage) => ({
  type: USER_ACTIONS.QUESTIONS_CURRENT_PAGE,
  questionsCurrentPage,
});

// fetch all address request
export const fetchAllAddressesRequest = () => ({
  type: USER_ACTIONS.FETCH_ALL_ADDRESSES_REQUEST,
});

export const fetchAllAddressesSuccess = (addressBook) => ({
  type: USER_ACTIONS.FETCH_ALL_ADDRESSES_SUCCESS,
  addressBook,
});

export const fetchAllAddressesFailed = () => ({
  type: USER_ACTIONS.FETCH_ALL_ADDRESSES_FAILED,
});

// update address request
export const updateAddressRequest = ({ addressId, address }) => ({
  type: USER_ACTIONS.UPDATE_ADDRESS_REQUEST,
  addressId,
  address,
});

export const updateAddressSuccess = () => ({
  type: USER_ACTIONS.UPDATE_ADDRESS_SUCCESS,
});

export const updateAddressFailed = () => ({
  type: USER_ACTIONS.UPDATE_ADDRESS_FAILED,
});

// add address request
export const createAddressRequest = (address) => ({
  type: USER_ACTIONS.CREATE_ADDRESS_REQUEST,
  address,
});

export const createAddressSuccess = () => ({
  type: USER_ACTIONS.CREATE_ADDRESS_SUCCESS,
});

export const createAddressFailed = () => ({
  type: USER_ACTIONS.CREATE_ADDRESS_FAILED,
});

export const setUserSelectedBillAddress = (selectedBillAddress) => ({
  type: USER_ACTIONS.USER_SELECTED_BILL_ADDRESS,
  selectedBillAddress,
});

export const setUserSelectedShipAddress = (selectedShipAddress) => ({
  type: USER_ACTIONS.USER_SELECTED_SHIP_ADDRESS,
  selectedShipAddress,
});
