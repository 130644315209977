import React from "react";

import ReactStars from "react-rating-stars-component";

import REACT_STARS from "constants/reactStarsConfig";

function UserIcon({ rating, fullName }) {
  let initials = fullName.match(/\b\w/g) || [];

  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();

  return (
    <div className="user">
      <div className="user-icon">{initials}</div>
      <div className="user-info ms-3">
        <h6 className="mb-0 font-weight-bold">{fullName}</h6>
        <ReactStars
          value={rating}
          count={REACT_STARS.COUNT}
          edit={false}
          size={REACT_STARS.USER_SIZE}
          activeColor={REACT_STARS.ACTIVE_COLOR}
        />
      </div>
    </div>
  );
}

export default UserIcon;
