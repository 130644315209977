import React, { useEffect } from "react";
import { I18n, Translate } from "react-redux-i18n";
import { reduxForm } from "redux-form";
import { Field } from "redux-form-normalize-on-blur";
import { useDispatch, useSelector, connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";

import {
  createProductQuestionRequest,
  editProductQuestionRequest,
} from "appRedux/productQuestion/actions";

import { required } from "utils/fieldValidation";
import { getFullName } from "utils/utils";

import Button from "components/Button";

import { renderTextAreaField } from "../Form/FormFieldRender";
import QuestionAnonymousCheckbox from "./QuestionAnonymousCheckbox";
import QuestionBasicInformationFields from "./QuestionBasicInformationFields";

function QuestionForm({ handleSubmit, history, pristine, showCancelButton }) {
  const { questionId } = useParams();

  const {
    userQuestion: { isLoading },
  } = useSelector((state) => state.productQuestion);
  const {
    product: { id },
  } = useSelector(({ productDetail }) => productDetail);
  const {
    questionsData: { questions },
    userInfoData: { userInfo },
  } = useSelector(({ user }) => user);

  const dispatch = useDispatch();

  const question = questions.find((question) => {
    return question.id === questionId;
  });

  const submit = (values) => {
    if (questionId) {
      return dispatch(
        editProductQuestionRequest({
          productId: question.product.id,
          questionId: questionId,
          content: values.content,
          isAnonymous: values.dontShowName,
          fullName:
            values.fullName || getFullName(userInfo.default_billing_address),
          email: values.email || userInfo.email,
          history,
        })
      );
    }

    dispatch(
      createProductQuestionRequest({
        userId: null,
        productId: id,
        content: values.content,
        isAnonymous: values.dontShowName,
        fullName:
          values.fullName || getFullName(userInfo.default_billing_address),
        email: values.email || userInfo.email,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <QuestionBasicInformationFields questionId={questionId} />
      <Field
        name="content"
        component={renderTextAreaField}
        label={`${I18n.t("form.fields.yourQuestion")} *`}
        validate={[required]}
      />

      <QuestionAnonymousCheckbox />
      <div className="d-flex flex-column-reverse flex-md-row gap-3 question-buttons-container">
        {showCancelButton && (
          <button
            type="button"
            onClick={() => history.goBack()}
            className="btn btn-secondary py-2 text-dark border py-3 px-5"
          >
            <Translate value="questions.cancel" />
          </button>
        )}
        <Button
          btnText={<Translate value="questions.button" />}
          className="btn btn-primary py-3 shadow-sm w-100"
          type="submit"
          disabled={pristine}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
}

const mapStateToProps = (state, props) => {
  const { questions } = state.user.questionsData;

  const question = questions.find((question) => {
    return question.id === props.match.params.questionId;
  });

  if (question) {
    return {
      initialValues: {
        content: question.content,
        dontShowName: !question.is_visible,
      },
    };
  }
};

export default withRouter(
  connect(mapStateToProps)(reduxForm({ form: "question" })(QuestionForm))
);
