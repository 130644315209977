import { Http } from '@spree/storefront-api-v2-sdk'

import { Routes } from 'lib/spreeSdk/routes'

export default class ProductReview extends Http {
  async list(productId, params) {
    return this.spreeResponse(
      'get',
      Routes.productReviewPath(productId),
      {}, // does not need token so pass empty object
      params,
    )
  }

  async listByUser(token, userId, params) {
    return this.spreeResponse(
      'get',
      Routes.userReviewPath(userId),
      token, // pass token for api call
      params,
    )
  }

  async createReview(token, productId, params) {
    const response = await this.spreeResponse(
      'post',
      Routes.productReviewPath(productId),
      token, // pass token for api call
      params,
    )

    return response
  }

  async updateReview(token, productId, reviewId, params) {
    const response = await this.spreeResponse(
      'patch',
      Routes.reviewDetailPath(productId, reviewId),
      token, // pass token for api call
      params,
    )

    return response
  }

  async removeReview(token, productId, reviewId) {
    const response = await this.spreeResponse(
      'delete',
      Routes.reviewDetailPath(productId, reviewId),
      token, // pass token for api call
    )

    return response
  }
}
