import React from "react";
import Pagination from "components/Pagination";
import { Translate } from "react-redux-i18n";

import ReviewItem from "./ReviewItem";

const ReviewCard = ({
  title,
  page,
  pageMetaData,
  pageNumberHandler,
  handleEditReview,
  handleDeleteReview,
  reviews,
}) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-3 pb-2">
        <h2 className="h6 d-inline font-weight-bold mb-0">{title}</h2>
        {reviews.length !== 0 && (
          <div>
            <button
              className="btn btn-secondary btn-sm text-muted dropdown-toggle p-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Translate value="user.myReviews.dropdown.all" />
            </button>
            <ul className="dropdown-menu border-0 shadow">
              <li>
                <a className="dropdown-item" href="#">
                  <Translate value="user.myReviews.dropdown.all" />
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <Translate value="user.myReviews.dropdown.toBeReviewed" />
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <Translate value="user.myReviews.dropdown.history" />
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
      {reviews.length !== 0 ? (
        reviews.map((review) => {
          return (
            <ReviewItem
              handleEditReview={handleEditReview}
              handleDeleteReview={handleDeleteReview}
              key={review.id}
              review={review}
            />
          );
        })
      ) : (
        <p className="text-muted">
          <Translate value="user.myReviews.noReviews" />
        </p>
      )}
      <Pagination
        page={page}
        pageMetaData={pageMetaData}
        pageNumberHandler={pageNumberHandler}
        className="justify-content-center mt-3"
      />
    </>
  );
};

export default ReviewCard;
