import React, { useEffect } from "react";
import {
  useLocation,
  useParams,
  withRouter,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";

import Footer from "components/Footer/Footer";
import Breadcrumb from "components/Breadcrumb";
import ProductItem from "components/Product/Item";
import CategoryList from "components/Category/CategoryList";
import Layout from "components/Layout/Layout";
import CategorySkeletion from "components/Skeleton/CategorySkeletion";

import {
  fetchProductsRequest,
  fetchcurrentPage,
  setSortBy,
  clearProductFilters,
  resetProducts,
} from "appRedux/products/actions";

import extractImage from "utils/extractImage";
import { getParams } from "utils/queryString";
import { filterProducts } from "utils/products";

import ROUTES from "constants/routes";

function Category({ history }) {
  const { search, pathname } = useLocation();
  const { page } = getParams(search);
  const { taxonId } = useParams();
  const { replace } = useHistory();

  const dispatch = useDispatch();
  const {
    products,
    isLoading,
    error,
    currentPage,
    pageMetaData,
    sortBy,
    selectedFilters,
  } = useSelector((state) => state.products);
  const { taxons } = useSelector((state) => state.taxons);

  const isFilterSelected =
    (selectedFilters.color && selectedFilters.color.length !== 0) ||
    (selectedFilters.size && selectedFilters.size.length !== 0);

  useEffect(() => {
    return () => {
      dispatch(resetProducts());
    };
  }, []);

  useEffect(() => {
    if (taxonId) {
      dispatch(
        fetchProductsRequest({
          filter: { taxons: taxonId },
          pageNumber: currentPage,
          sortBy,
          perPage: 25,
        })
      );
    }
  }, [taxonId, currentPage, sortBy]);

  const pageNumberHandler = (fetchPage) => {
    dispatch(fetchcurrentPage(fetchPage));

    const newPath = `${pathname}?${
      fetchPage ? `page=${fetchPage}` : ""
    }${`&sortBy=${sortBy}`}`;

    replace(newPath);

    if (!taxonId) {
      return history.push(`${ROUTES.HOME.INDEX}`);
    }
  };

  const handleSort = (value) => {
    dispatch(setSortBy(value));
  };

  useEffect(() => {
    dispatch(clearProductFilters());
  }, []);

  useEffect(() => {
    const nextPage = page || 1;

    pageNumberHandler(nextPage);
  }, [page, sortBy]);

  useEffect(() => {
    if (taxonId || sortBy) {
      pageNumberHandler(1);
    }
  }, [taxonId, sortBy]);

  useEffect(() => {
    return () => {
      dispatch(setSortBy("default"));
    };
  }, []);

  let taxonName = "";

  if (products[taxonId]) {
    taxonName = products[taxonId].name;
  }

  if (isLoading) {
    return <CategorySkeletion />;
  }

  let filteredProducts = [];

  if (products[taxonId]) {
    filteredProducts = filterProducts(
      products[taxonId].products,
      selectedFilters
    );
  }

  const getTaxon = taxons.find((taxon) => {
    return taxon.id === taxonId;
  });

  return (
    <Layout
      title={taxonName}
      description={getTaxon?.description}
      metaImage={getTaxon?.image}
      path={ROUTES.CATEGORY.INDEX}
    >
      <Breadcrumb secondStep={taxonName} />
      <CategoryList
        sortBy={sortBy}
        taxonImage={getTaxon?.image}
        taxonDescription={getTaxon?.description}
        taxonName={taxonName}
        handleSort={handleSort}
        pageMetaData={pageMetaData}
        page={currentPage}
        productLength={filteredProducts.length}
        pageNumberHandler={pageNumberHandler}
        category={`${taxonName} (${filteredProducts.length})`}
      >
        {filteredProducts.length !== 0 ? (
          filteredProducts.map((product) => {
            const {
              id,
              price,
              display_price,
              images,
              name,
              slug,
              taxons,
            } = product;
            const image = extractImage(images);

            return (
              <ProductItem
                key={id}
                className="col-6 col-md-4"
                categoryId={taxonId}
                image={image}
                title={name}
                newPrice={display_price}
                slug={slug}
                link={`${ROUTES.PRODUCTS.INDEX}/${slug}`}
              />
            );
          })
        ) : (
          <p className="mt-4">No product found</p>
        )}
      </CategoryList>

      <Footer />
    </Layout>
  );
}

export default withRouter(Category);
