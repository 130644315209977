import React, { useState, useEffect } from "react";
import { I18n, Translate } from "react-redux-i18n";
import { useSelector } from "react-redux";

import UserIcon from "components/UserIcon";
import Button from "components/Button";

import { formatDate } from "utils/DateFormatter";

const ProductReviews = ({
  productReviews,
  setToggleReviewForm,
  toggleReviewForm,
}) => {
  const toggleReviewFormHandler = () => {
    setToggleReviewForm(!toggleReviewForm);
  };
  const { reviewSubmitted } = useSelector((state) => state.productReview);

  useEffect(() => {
    if (reviewSubmitted) {
      setToggleReviewForm(false);
    }
  }, [reviewSubmitted]);

  return (
    <>
      <ul className="product-review list-style-none ps-0">
        {productReviews.length !== 0 &&
          productReviews.map((productReview) => {
            const {
              id,
              title,
              review,
              rating,
              show_identifier,
              created_at,
              user,
            } = productReview;

            const hasDefaultBillAdress = Boolean(
              user?.default_billing_address?.firstname
            );

            const fullName =
              show_identifier && hasDefaultBillAdress
                ? `${user.default_billing_address.firstname} ${user.default_billing_address.lastname}`
                : "Anonymous";

            return (
              <li key={id} className="review border-bottom mb-3">
                <UserIcon rating={rating} fullName={fullName} />
                <div className="mt-3 mb-1 d-flex  align-items-end justify-content-between p-2">
                  <div>
                    <p className="font-weight-bold">{title}</p>
                    <p className="text-secondary mb-0">{review}</p>
                  </div>
                  <div className="text-muted">
                    <Translate
                      value="productDetail.reviews.reviewedIn"
                      date={formatDate(created_at)}
                    />
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
      <Button
        btnText={I18n.t("productDetail.reviews.buttontext")}
        className="w-100 bg-light primary-color"
        onClick={toggleReviewFormHandler}
      />
    </>
  );
};

ProductReviews.propTypes = {};

export default ProductReviews;
