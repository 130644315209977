import fallbackImage from "assets/images/loader/placeholder-image.jpg";

const extractImage = (images) => {
  let imageUrl;

  if (images?.length) {
    imageUrl = images[0]?.styles[images[0].styles.length - 1].url;
  }

  const image = imageUrl ? `${imageUrl}` : fallbackImage;

  return image;
};

export default extractImage;
