import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import OrderSummarySide from "components/OrderSummarySide/OrderSummarySide";
import Layout from "components/Layout/Layout";

import { emptyCartRequest } from "appRedux/cart/actions";

import fallbackImage from "assets/images/loader/placeholder-image.jpg";

import ROUTES from "constants/routes";

import { isEnvironmentVariableEnabled } from "utils/isEnvironmentVariableEnabled";

import CartProductItem from "./CartProductItem";

function ProductPreview() {
  const { cartData } = useSelector((state) => state.cart);

  const dispatch = useDispatch();
  const handleEmptyCart = () => {
    dispatch(emptyCartRequest());
  };

  return (
    <Layout title="Cart | Trendline Store" path={ROUTES.CART.INDEX}>
      <section className="py-4 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8 pe-md-5 mb-3 mb-md-0">
              <div className="card border-0 shadow-sm p-3">
                <div className="d-flex justify-content-between align-items-center border-bottom pb-3">
                  <h2 className="h4 font-weight-bold mb-0">
                    <Translate value="cart.yourCart" />
                  </h2>
                  {cartData.item_count !== 0 && (
                    <button
                      className="btn btn-link text-gray-600 p-2"
                      type="button"
                      onClick={handleEmptyCart}
                    >
                      <FontAwesomeIcon icon={faTrash} />{" "}
                      <Translate value="cart.emptyCartButton" />
                    </button>
                  )}
                </div>

                <ul className="list-unstyled">
                  {cartData.item_count === 0 ? (
                    <p>
                      <Translate value="cart.cartIsEmpty" />
                    </p>
                  ) : (
                    <>
                      {cartData.line_items[0].name &&
                        cartData.line_items.map((cartItem) => {
                          const imageUrl = cartItem.variant.images.length
                            ? cartItem.variant.images[0]?.styles[
                                cartItem.variant.images[0].styles.length - 1
                              ].url
                            : isEnvironmentVariableEnabled(
                                process.env
                                  .REACT_APP_ENABLE_FALLBACK_TO_PRODUCT_IMAGE
                              )
                            ? cartItem.variant.product.images[0]?.styles[
                                cartItem.variant.product.images[0].styles
                                  .length - 1
                              ]?.url
                            : undefined;

                          const image = imageUrl
                            ? `${imageUrl}`
                            : fallbackImage;

                          return (
                            <CartProductItem
                              key={cartItem.id}
                              id={cartItem.id}
                              quantity={cartItem.quantity}
                              image={image}
                              title={cartItem.name}
                              options_text={cartItem.options_text}
                              price={cartItem.display_total}
                            />
                          );
                        })}
                    </>
                  )}
                </ul>
              </div>
            </div>

            <div className="col-md-4">
              <OrderSummarySide cartData={cartData} procceedToCheckOutButton />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default React.memo(ProductPreview);
