import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import { normalizeData } from 'services/jsonaDataFormatter';
import UserApi from 'api/user/userApi';

import {
  userInfoSuccess,
  orderListSuccess,
  fetchAllAddressesFailed,
  fetchAllAddressesSuccess,
  updateAddressSuccess,
  updateAddressFailed,
  createAddressSuccess,
  createAddressFailed,
  fetchReviewsSuccess,
  fetchQuestionsSuccess,
  setUserSelectedBillAddress,
  setUserSelectedShipAddress,
} from './actions';

import USER_ACTIONS from './constants';

const getUserState = (state) => state.user;

// user-info
function* userInfoRequest() {
  try {
    const userInfo = yield call(UserApi.userInfoRequest);

    if (userInfo.isSuccess()) {
      const userInfodata = normalizeData(userInfo.success());

      yield put(
        userInfoSuccess({
          userInfo: userInfodata,
        })
      );
    } else {
      throw userInfo.fail();
    }
  } catch (e) {}
}

// order-list
function* orderListRequest({ currentPage }) {
  try {
    const completedOrder = yield call(UserApi.usercompletedOrdersList, {
      pageNumber: currentPage,
      perPage: 5,
    });

    if (completedOrder.isSuccess()) {
      const completedOrderdata = normalizeData(completedOrder.success());
      const pageMetaData = completedOrder.success().meta;

      yield put(
        orderListSuccess({
          orders: completedOrderdata,
          ordersPageMetaData: pageMetaData,
        })
      );
    } else {
      throw completedOrder.fail();
    }
  } catch (e) {}
}

// fetch-all-address-book
function* fetchAllAddressesRequest() {
  try {
    const fetchAllAddresses = yield call(UserApi.fetchAllAddresses);

    if (fetchAllAddresses.isSuccess()) {
      const fetchAllAddressesData = normalizeData(fetchAllAddresses.success());

      yield put(fetchAllAddressesSuccess(fetchAllAddressesData));

      const {
        addressBookData: { addressBook },
      } = yield select(getUserState);

      if (addressBook.length !== 0) {
        yield put(setUserSelectedBillAddress(addressBook[0]));
        yield put(setUserSelectedShipAddress(addressBook[0]));
      }
    } else {
      throw fetchAllAddresses.fail();
    }
  } catch (e) {
    yield put(fetchAllAddressesFailed());
  }
}

// update-address
function* updateAddressRequest({ addressId, address }) {
  try {
    const updateAddress = yield call(UserApi.updateAddress, {
      addressId,
      address,
    });

    if (updateAddress.isSuccess()) {
      yield put({ type: USER_ACTIONS.FETCH_ALL_ADDRESSES_REQUEST });
      yield put(updateAddressSuccess());
    } else {
      throw updateAddress.fail();
    }
  } catch (e) {
    yield put(updateAddressFailed());
  }
}

// create-address
function* createAddressRequest({ address }) {
  try {
    const createAddress = yield call(UserApi.createAddress, address);

    if (createAddress.isSuccess()) {
      yield put({ type: USER_ACTIONS.FETCH_ALL_ADDRESSES_REQUEST });
      yield put(createAddressSuccess());
    } else {
      throw createAddress.fail();
    }
  } catch (e) {
    yield put(createAddressFailed());
  }
}

function* userReviewsRequest({ currentPage, userId }) {
  try {
    const userReviews = yield call(UserApi.fetchUserReviews, {
      pageNumber: currentPage,
      perPage: 5,
      userId,
    });

    if (userReviews.isSuccess()) {
      const userReviewsdata = normalizeData(userReviews.success());
      const pageMetaData = userReviews.success().meta;

      yield put(
        fetchReviewsSuccess({
          reviews: userReviewsdata,
          reviewsPageMetaData: pageMetaData,
        })
      );
    } else {
      throw userReviews.fail();
    }
  } catch (e) {}
}

function* userQuestionsRequest({ currentPage, userId }) {
  try {
    const userQuestions = yield call(UserApi.fetchUserQuestions, {
      pageNumber: currentPage,
      perPage: 5,
      userId,
    });

    if (userQuestions.isSuccess()) {
      const userQuestionsdata = normalizeData(userQuestions.success());
      const pageMetaData = userQuestions.success().meta;

      yield put(
        fetchQuestionsSuccess({
          questions: userQuestionsdata,
          questionsPageMetaData: pageMetaData,
        })
      );
    } else {
      throw userQuestions.fail();
    }
  } catch (e) {}
}

export function* watchUserInfoRequest() {
  yield takeEvery(USER_ACTIONS.USER_INFO_REQUEST, userInfoRequest);
}

export function* watchOrderListRequest() {
  yield takeEvery(USER_ACTIONS.ORDER_LIST_REQUEST, orderListRequest);
}

export function* watchfetchAllAddressesRequest() {
  yield takeEvery(
    USER_ACTIONS.FETCH_ALL_ADDRESSES_REQUEST,
    fetchAllAddressesRequest
  );
}

export function* watchUpdateAddressRequest() {
  yield takeEvery(USER_ACTIONS.UPDATE_ADDRESS_REQUEST, updateAddressRequest);
}

export function* watchCreateAddressRequest() {
  yield takeEvery(USER_ACTIONS.CREATE_ADDRESS_REQUEST, createAddressRequest);
}
export function* watcUserReviewsRequest() {
  yield takeEvery(USER_ACTIONS.FETCH_USER_REVIEWS_REQUEST, userReviewsRequest);
}

export function* watcUserQuestionsRequest() {
  yield takeEvery(
    USER_ACTIONS.FETCH_USER_QUESTIONS_REQUEST,
    userQuestionsRequest
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchUserInfoRequest),
    fork(watchOrderListRequest),
    fork(watchfetchAllAddressesRequest),
    fork(watchUpdateAddressRequest),
    fork(watchCreateAddressRequest),
    fork(watcUserReviewsRequest),
    fork(watcUserQuestionsRequest),
  ]);
}
