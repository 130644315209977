import React, { useState } from "react";
import { FiUser, FiShoppingCart, FiSearch } from "react-icons/fi";
import { Link, withRouter } from "react-router-dom";
import { Translate } from "react-redux-i18n";
import { useSelector, useDispatch } from "react-redux";
import {
  openCartModal,
  closeCartModal,
  handleCartButtonClick,
  handleResetCartButtonClick,
} from "appRedux/cart/actions";
import { resetUserData } from "appRedux/user/actions";
import { logout } from "appRedux/auth/actions";

import ROUTES from "constants/routes";
import CART_ACTIONS from "appRedux/cart/constants";
import CHECKOUT_ACTIONS from "appRedux/checkOut/constants";
import { WISHLIST_ACTIONS } from "appRedux/wishlist/constants";

import CartDropdown from "./CartDropdown";

function HeaderIcon({ itemCount, displayTotal, className, history }) {
  const [showUserDropdown, setshowUserDropdown] = useState(false);

  const { modal, isCartButtonClicked } = useSelector((state) => state.cart);

  const bearerToken = localStorage.getItem("bearerToken");

  const dispatch = useDispatch();

  const handleCartClick = () => {
    dispatch(handleCartButtonClick());
    setshowUserDropdown(true);

    if (modal) {
      if (isCartButtonClicked) {
        dispatch(closeCartModal());
        dispatch(handleResetCartButtonClick());
      }
    } else {
      dispatch(openCartModal());
    }
  };

  const handleModalClose = () => {
    setshowUserDropdown(false);

    if (!isCartButtonClicked) {
      dispatch(closeCartModal());
    }
  };

  const onDropDownClick = () => {
    dispatch(closeCartModal());
    dispatch(handleResetCartButtonClick());
  };

  const handleCartMouseOver = () => {
    if (!isCartButtonClicked) {
      if (!modal) {
        dispatch(openCartModal());
      }
    }
  };

  const handleUserDropdown = () => {
    dispatch(closeCartModal());
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(resetUserData());
    dispatch({ type: CART_ACTIONS.RESET_CART });
    dispatch({ type: CHECKOUT_ACTIONS.CLEAR_ORDER_DETAILS });
    dispatch({ type: CHECKOUT_ACTIONS.RESET_CHECKOUT });
    dispatch({ type: WISHLIST_ACTIONS.RESET_WISHLIST });
    dispatch(logout());
    history.push(ROUTES.HOME.INDEX);
    // TODO: fixing the error without reload
    // for fixing "too many rerenders" error
    window.location.reload();
  };

  return (
    <>
      {(modal || showUserDropdown) && (
        <div
          className="cart-modal-backdrop"
          onMouseOver={() => handleModalClose()}
          onClick={() => onDropDownClick()}
        />
      )}
      <div className={`w-auto header-icons me-2 me-md-0 ${className}`}>
        {/* <Link to="#" className="d-block d-md-none">
        <div className="user-icon d-flex align-items-center">
          <FiSearch className="me-0 me-md-2" />
        </div>
      </Link> */}
        <div className="dropdown">
          <button
            className="btn btn-secondary btn-sm text-muted p-0 user-icon"
            type="button"
            onMouseOver={handleUserDropdown}
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="user-icon d-flex align-items-center">
              <FiUser className="me-3 me-md-3" />
              {/* <div className="text-secondary lh-sm">
            <small>Hello, Sign in</small> <br /> My Account
          </div> */}
            </div>
          </button>
          <ul className="dropdown-menu position-absolute end-0 border-0 shadow">
            {bearerToken ? (
              <>
                <li>
                  <Link className="dropdown-item" to={ROUTES.USER.PROFILE}>
                    <Translate value="user.dropdown.myAccount" />
                  </Link>
                </li>
                <li>
                  <button className="dropdown-item" onClick={handleLogout}>
                    <Translate value="user.dropdown.logout" />
                  </button>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link className="dropdown-item" to={ROUTES.LOGIN.INDEX}>
                    <Translate value="user.dropdown.login" />
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={ROUTES.SIGNUP.INDEX}>
                    <Translate value="user.dropdown.signUp" />
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>

        <div>
          <div className="dropdown">
            <div
              className="d-flex align-items-center"
              id="dropdownMenuButton"
              onClick={() => handleCartClick()}
              onMouseOver={() => {
                handleCartMouseOver();
              }}
              touchstart={(e) => {
                e.preventDefault();
                handleCartMouseOver();
              }}
            >
              <div className="cart-icon">
                <FiShoppingCart />
                <div className="cart-item-count">{itemCount}</div>
              </div>
              {/* <div className="text-secondary ms-3 lh-sm">
              <small>My Cart</small> <br /> {displayTotal}
            </div> */}
            </div>

            {modal && (
              <CartDropdown
                totalAmount={displayTotal}
                showCartDropdown={showUserDropdown}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(HeaderIcon);
