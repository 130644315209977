import axios from 'axios';

export const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const URL = `${process.env.REACT_APP_API_URL}/api/v2/storefront/countries/161?include=states`;

class StateApi {
  static sendstateAddressRequest() {
    return axios.get(URL, {
      headers: DEFAULT_HEADERS,
    });
  }
}

export default StateApi;
