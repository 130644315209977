// NOTE:
// Please maintain by alphabetical order; it will be easier to search and look through the file.

export default {
  carousel: {
    buttons: {
      shopNow: "SHOP NOW",
    },
  },
  cart: {
    cartIsEmpty: "Cart is Empty",
    emptyCartButton: "Empty cart",
    yourCart: "Your Cart",
  },
  cartDropdown: {
    checkOut: "Checkout",
    expandCart: "Expand Cart",
    subTotal: "Subtotal",
  },
  categoryCarousel: {
    titles: {
      bestSeller: "Best Sellers",
      relatedProducts: "Related Products",
      trending: "Trending",
    },
  },
  checkout: {
    address: {
      address: "Address",
      addressName: "Address Name",
      city: "City",
      close: "Close",
      district: "District",
      email: "Email",
      emailHelperText: "We'll never share your email with anyone else.",
      firstName: "First Name",
      lastName: "Last Name",
      phone: "Phone",
      region: "Region",
      saveChanges: "Save changes",
      stateProvince: "State/Province",
      submit: "Submit",
      zipCode: "Zip Code",
    },
    delivery: {
      title: "Shipping",
      button: {
        saveChanges: "Save Changes",
        cancel: "Cancel",
      },
      chooseDeliveryOptions: "Choose delivery option",
      noSpecialInstructionProvided: "No special instruction provided.",
      specialInstruction: "Special Instructions",
      selectedDeliveryOption: "Selected Delivery Option",
    },
    payment: {
      title: "Payment",
      choosePaymentMethod: "Choose payment method",
    },
  },
  common: {
    error: "Error",
    loading: "Loading",
  },
  footer: {
    contactUs: {
      title: "Contact us",
      email: "Email",
      virtualBazar: "Virtual Bazaar Private Limited,",
      address: "Anamnagar, Kathmandu",
      callUsNow: "Call us now",
    },
    followUs: {
      downlaodOurApp: "Download our app",
      title: "Follow us",
    },
    mainText: `%{storeName} - Buy directly from the importers.`,

    user: {
      myAccount: "MY ACCOUNT",
      myOrders: "MY ORDERS",
    },
  },
  featureStrip: {
    easyReturn: "Easy Return",
    freeDelivery: "Easy Delivery",
    genuineProducts: "Genuine Products",
  },
  featuredCategory: {
    buttons: {
      viewMore: "VIEW MORE",
      viewProducts: "VIEW PRODUCTS",
    },
  },
  form: {
    fields: {
      confirmPassword: "Confirm Password",
      dontShowName: `Don't Show my name`,
      email: "Email",
      emailHelperText: "We'll never share your email with anyone else.",
      fullName: "Full name",
      password: "Password",
      title: "Title",
      yourReview: "Your review",
      yourQuestion: "Your Question",
    },
    errors: {
      required: "Required",
      invalidEmail: "Invalid email address",
      phoneNumber: "Invalid phone number, must be 10 digits",
      passwordConfirmation:
        "Password must be at least one upper case , one lower case, one digit,one special character & minimum six in length.",
      passwordDontMatch: "Password donot match please re-type your password.",
      zipcode: "Invalid zipcode, must be 5 postive digits",
    },
    placeholder: {
      email: "Please enter your email",
      password: "Please enter your password",
      retypePassword: "Please retype your password",
    },
  },
  header: {
    searchboxPlaceHolder: "I am searching for...",
  },
  guest: {
    title: "Continue as Guest",
  },
  login: {
    login: "LOGIN",
    orloginWith: "Or, login with",
    forgetpassword: "Forgot Password ?",
    title: "Welcome to our store! Please login.",
    newMember: "New member? ",
    here: "here",
  },
  orderSummary: {
    deliveryOptions: "Delivery options",
    orderSummary: {
      applyCode: "Apply code",
      discount: "Discount",
      proceedToCheckout: "Proceed To Checkout",
      removeCode: "Remove Code",
      subTotal: "Subtotal",
      shipping: "Shipping",
      title: "Order Summary",
      totalItems: "Total Items",
      tax: "Tax",
      total: "Total",
    },
    procceedToPayment: "Proceed To Payment",
    shippingAndBilling: {
      billToSameAddress: "Bill to the same address",
      title: "Shipping & Billing",
    },
  },
  orderDetails: {
    billingAddress: "Billing Address",
    shippingAddress: "Shipping Address",
    order: "Order",
    orderSuccess: "Order Placed Successfully",
    orderSentToEmail: "Your order information will be sent to your email.",
    payment: "Payment",
    product: {
      price: "Price",
      quantity: "Quantity",
      total: "Total",
    },
    shipping: "Shipping",
    specialInstruction: "Special Instruction",
  },
  productDetail: {
    availability: "Availability",
    buttons: {
      addToCart: "Add to cart",
    },
    description: {
      details: "Details",
      specifications: "Specifications",
      title: "Description",
    },
    reviews: {
      buttontext: "Write a Review",
      title: "Review",
      title_plural: "Reviews",
      reviewedIn: "Reviewed on: %{date}",
    },
    soldBy: "Sold By: %{name}",
  },
  productBanner: {
    buttons: {
      readMore: "READ MORE",
    },
    category: "LATEST PRODUCT",
    title: "Sports Style Home Office Gaming chair with Footrest",
  },
  promoCode: {
    promoCodeApplied: "Promocode Applied",
    promoCodeRemoved: "Promocode Removed",
    title: "Promocode",
  },
  questions: {
    askQuestionButton: "Ask your questions",
    button: "Add Question",
    customerQuestionsAndAnswers: "Customer questions & answers",
    cancel: "Cancel",
    questionSubmitted: "Question Submitted",
    title: "Do you have any question?",
    questionedIn: "By %{displayName} on %{date}",
  },
  review: {
    button: "Add review",
    cancelButton: "Cancel",
    loginToAddReview: "Login to add a review",
    reviewSubmitted: "Review Submitted",
    title: " Add your review",
  },
  socials: {
    facebook: "Facebook",
    google: "Google",
  },
  signup: {
    alreadymember: "Already member?",
    orSignUpwith: "Or, sign up with",
    privacypolicy: `By clicking “SIGN UP”, I agree to Trendline's Terms of Use and Privacy Policy.`,
    signUp: "SIGN UP",
    title: "Create your Account",
  },
  user: {
    addressBook: {
      address: "Address",
      addressName: "Address Name",
      addNewAddress: "Add New Address",
      button: {
        addNewAddress: "ADD NEW ADDRESS",
        cancel: "Cancel",
        change: "Change",
        edit: "Edit",
        save: "Save",
      },
      defaultShippingAddress: "Default Shipping Address",
      defaultBillingAddress: "Default Billing Address",
      editMyAddress: "Edit My Address",
      fullName: "Full name",
      noAddress: "No address added yet !",
      phoneNumber: "Phone Number",
      title: "Address Book",
    },
    dashboard: {
      title: "Dashboard",
    },
    dropdown: {
      logout: "Logout",
      login: "Login",
      myAccount: "My Account",
      signUp: "Signup",
    },
    myOrders: {
      emptyOrderList: "No orders yet !",
      order: "Order",
      placedOn: "Placed on",
      status: "Status",
      quantity: "Qty",
      title: "My Orders",
    },
    myReviews: {
      dropdown: {
        all: "All",
        deleteReview: "Delete Review",
        editReview: "Edit Review",
        history: "History",
        toBeReviewed: "To be reviewed",
      },
      noReviews: "No reviews yet !",
      reviewedOn: "Reviewed on %{date}",
      status: "Status",
      title: "My Reviews",
    },
    myQuestions: {
      answer: "Answer",
      answered: "Answered",
      dropdown: {
        all: "All",
        deleteQuestion: "Delete Question",
        editQuestion: "Edit Question",
        history: "History",
        toBeReviewed: "To be reviewed",
      },
      noQuestions: "No questions yet !",
      pendingReview: "Pending Review",
      question: "Question",
      questionedOn: "Question asked on %{date}",
      status: "Status",
      title: "My Questions",
    },
    myWishList: {
      available: "Available",
      alert: {
        button: {
          ok: "Ok",
          cancel: "Cancel",
        },
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover !",
      },
      availability: "Availability",
      dropDown: {
        addNewList: "Add new list",
        default: "Default",
        editThisList: "Edit this list",
        manageWishlist: "Manage Wishlists",
        makePrivate: "Make private",
        moveItem: "Move item",
        removeItem: "Remove item",
        shareWishlist: "Share wishlist",
      },
      form: {
        deleteWishlist: "Delete this wishlist",
        isPrivate: "Is Private",
        isDefault: "Is Default",
        name: "Name",
        save: "Save",
      },
      noProduct: "No products in this wishlist",
      product: {
        price: "Price",
        quantity: "Qty",
      },
      placedOn: "Placed on",
      title: "My Wishlists",
      outOfStock: "Out of Stock",
      wishlist: "Wishlist",
    },
    profile: {
      billingAddress: "Billing Address",
      contactInfo: "Contact Information",
      shippingAddress: "Shipping Address",
      title: "Profile",
    },
  },
};
