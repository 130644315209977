import client from 'services/spreeClient'
import getToken from 'services/getToken'

class ProductReviewApi {
  static fetchAllReviews(productId) {
    return client.reviews.list(productId, {
      include: 'user.default_billing_address',
    })
  }

  static createProductReview({
    review,
    title,
    name,
    rating,
    productId,
    showIdentifier,
  }) {
    const reviewParams = {
      review: {
        rating,
        title,
        review,
        name,
        show_identifier: showIdentifier,
      },
    }

    return client.reviews.createReview(
      { ...getToken() },
      productId,
      reviewParams,
    )
  }

  static editProductReview({
    review,
    title,
    name,
    rating,
    productId,
    showIdentifier,
    reviewId,
  }) {
    const reviewParams = {
      review: {
        rating,
        title,
        review,
        name,
        show_identifier: showIdentifier,
      },
    }

    return client.reviews.updateReview(
      { ...getToken() },
      productId,
      reviewId,
      reviewParams,
    )
  }

  static removeProductReview({ productId, reviewId }) {
    return client.reviews.removeReview({ ...getToken() }, productId, reviewId)
  }
}

export default ProductReviewApi
